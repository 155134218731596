export const timeFrame = [
    { key: "Now", value: "Now" },
    { key: "1 Month", value: "1 Month" },
    { key: "2 Months", value: "2 Months" },
    { key: "3 Months", value: "3 Months" },
    { key: "1-3 Months", value: "1-3 Months" },
    { key: "3-6 Months", value: "3-6 Months" },
    { key: "6-9 Months", value: "6-9 Months" },
    { key: "9-12 Months", value: "9-12 Months" },
];  

export const status = [
    { key: "New", value: "New" },
    { key: "Prospect", value: "Prospect" },
    { key: "Visit in Progress", value: "visit_in_progress" },
    { key: "Qualified", value: "qualified" },
    { key: "Contract Sent", value: "contract_sent" },
    { key: "Contract Signed", value: "contract_signed" },
    { key: "Not Ready", value: "not_ready" },
    { key: "Not Interested", value: "not_interested" },
    { key: "Called No Reply", value: "called_no_reply" },
];

export const propertyType = [
    { key: "Apartment", value: "AP" }, 
    { key: "Duplex", value: "DX" }, 
    { key: "Penthouse", value: "PH" }, 
    { key: "Townhouse", value: "TH" },
    { key: "Villa", value: "VH" }, 
    { key: "Short term/ Hotel Apartment", value: "SH" }, 
];  

export const source = [
    { key: "Property Finder", value: "Property Finder" }, 
    { key: "Portal", value: "Portal" }, 
    { key: "Social Media", value: "Social Media" }, 
    { key: "Online", value: "Online" }, 
    { key: "Offline", value: "Offline" },
    { key: "Other", value: "Other" }, 
];  
