import React, { useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Form, Input, Button, Row, Col, Card, message, Spin } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { authAxios } from "../../utils/axiosMiddleware";
import { EditUser } from "../../api/auth";
import { FormattedMessage } from "react-intl";

export const UserProfile = () => {
  const auth = JSON.parse(localStorage.getItem("userData"));

  const [form] = Form.useForm();

  const {
    data: userProfile,
    isLoading: profileLoading,
    isSuccess,
    refetch,
  } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["UserProfile"],
    queryFn: () =>
      authAxios.get(`users/${auth?.id}`, {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }),
    select: (res) => res?.data,
  });

  useEffect(() => {
    if (isSuccess && userProfile) {
      form.setFieldsValue({
        name: userProfile?.name,
        email: userProfile?.email,
      });
    }
  }, [isSuccess, userProfile, form]);

  const mutation = useMutation({
    mutationFn: (updatedData) => EditUser(auth?.id, updatedData),
    onSuccess: (response) => {
      message.success("Profile updated successfully!");
      const updatedUserData = { ...auth, ...response.data };
      localStorage.setItem("userData", JSON.stringify(updatedUserData));
      refetch();
    },
    onError: () => {
      message.error("Failed to update profile");
    },
  });

  const onFinish = (values) => {
    const updatedData = { ...values };
    if (!values.password) {
      delete updatedData.password;
      delete updatedData.confirm;
    }
    mutation.mutate(updatedData);
  };

  if (profileLoading) {
    return <Spin />;
  }

  return (
    <div
      style={{
        paddingTop: "150px",
        background: "#f0f2f5",
        minHeight: "65vh",
      }}
    >
      <Row justify="center">
        <Col span={12}>
          <Card
            title="Edit Profile"
            bordered={false}
            style={{
              borderRadius: "10px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              padding: "20px",
              backgroundColor: "#fff",
            }}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              initialValues={{
                name: userProfile?.name,
                email: userProfile?.email,
              }}
            >
              <Form.Item
                name="name"
                label="Full Name"
                rules={[
                  { required: true, message: "Please enter your full name" },
                ]}
                style={{ paddingTop: "20px" }}
              >
                <Input
                  placeholder="Enter your full name"
                  style={{ padding: "10px" }}
                />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please enter your email" }]}
              >
                <Input
                  disabled
                  placeholder="Enter your email"
                  style={{ padding: "10px" }}
                />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="please enter password" />,
                  },
                  {
                    pattern: /[A-Z]/,
                    message: "- Must have one uppercase letter (A-Z).",
                  },
                  {
                    pattern: /[a-z]/,
                    message: "- Must have one lowercase letter (a-z).",
                  },
                  {
                    pattern: /\d/,
                    message: "- Must have one number (0-9).",
                  },
                  {
                    pattern: /[!@#$%^&*]/,
                    message: "- Must have one special character (!@#$%^&*).",
                  },
                  {
                    min: 8,
                    message: "- Must be at least 8 characters long.",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your new password"
                  prefix={<LockOutlined />}
                  style={{ padding: "10px" }}
                />
              </Form.Item>

              <Form.Item
                name="confirm"
                label={<FormattedMessage id="Confirm Password" />}
                dependencies={["password"]}
                hasFeedback
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("password") && !value) {
                        return Promise.reject(
                          new Error("Please confirm your password")
                        );
                      }

                      if (value && getFieldValue("password") !== value) {
                        return Promise.reject(
                          new Error("The passwords do not match!")
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  prefix={<LockOutlined />}
                  style={{ padding: "10px" }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={mutation.isLoading}
                  className="arClass"
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
