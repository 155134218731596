/** @format */

import { Divider, Row, Col } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/footer.css";
import { FormattedMessage } from "react-intl";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="footer-divider-container">
        <Divider />
      </div>
      <Row className="footer-main-container">
        <Col xs={24} lg={5} className="footer-logo">
          <div onClick={() => navigate("/")}>
            <img
              alt="logo"
              src="/icons/Logo Colored.svg"
              style={{ cursor: "pointer" }}
            />
          </div>
        </Col>
        <Col xs={24} lg={4}>
          <div className="footer-listing-alignment">
            <span className="data-heading">
              <FormattedMessage id="feature" />
            </span>
            <span className="data" onClick={() => navigate("/")}>
              <FormattedMessage id="home" />
          
            </span>
            <span className="data2">
              <FormattedMessage id="blog" />
            </span>
            <span onClick={() => navigate("/contact-us")} className="data">
              <FormattedMessage id="contact" />
            </span>
          </div>
        </Col>
        <Col xs={24} lg={4}>
          <div className="footer-listing-alignment">
            <span className="data-heading">
              <FormattedMessage id="t_and_p" />
            </span>
            <span
              onClick={() => navigate("/terms-of-conditions")}
              className="data"
            >
              <FormattedMessage id="t_and_s" />
            </span>
            <span onClick={() => navigate("/privacy-policy")} className="data">
              <FormattedMessage id="privacy_policy" />
            </span>
            <span className="data2">
              <FormattedMessage id="security" />
            </span>
          </div>
        </Col>
        <Col xs={24} lg={7}>
          <div className="footer-listing-alignment">
            <span className="data-heading">
              {" "}
              <FormattedMessage id="contact_us" />
            </span>
            <span className="data2">
              {" "}
              <FormattedMessage id="call" />: +97126277156{" "}
            </span>
            <span className="data2">
              <FormattedMessage id="contact_us_for" />
            </span>
            <span className="data2">
              {" "}
              <FormattedMessage id="email" />: info@sandseekers.ae
            </span>
            <span className="data2">
              <FormattedMessage id="address" />: Office 2202, Floor 22,
              Commercial Tower A, C-88 Building, Electra Street, Abu Dhabi
            </span>
          </div>
        </Col>
        <Col xs={24} lg={4}>
          <div className="footer-listing-alignment">
            <span className="data-heading">
              {" "}
              <FormattedMessage id="join_us_at" />:
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                marginTop: "30px",
              }}
            >
              <div style={{ width: "50px", height: "50px" }}>
                <Link
                  to="https://www.linkedin.com/company/sand-seekers-real-estate-llc/"
                  target="_blank"
                >
                  <img
                    src="/icons/linkedin.webp"
                    alt="instagram"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Link>
              </div>
              <div
                style={{ width: "50px", height: "50px", marginLeft: "20px" }}
              >
                <Link
                  to="https://www.facebook.com/profile.php?id=61560022119418"
                  target="_blank"
                >
                  <img
                    src="/icons/facebook.svg"
                    alt="facebook"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Link>
              </div>
              <div
                style={{ width: "50px", height: "50px", marginLeft: "20px" }}
              >
                <Link
                  to="https://www.instagram.com/sandseekersrealestatellc/"
                  target="_blank"
                >
                  <img
                    src="/icons/instagram.png"
                    alt="facebook"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
