import { Axios } from "../../utils/axiosMiddleware";

export const registerUser = (body) => {
  return Axios.post("/auth/register", body);
};

export const LoginUser = (body) => {
  return Axios.post("/auth/login", body);
};

export const EditUser = (userId, updatedData) => {
  const auth = JSON.parse(localStorage.getItem("userData"));
  return Axios.put(`users/${userId}`, updatedData, {
    headers: {
      Authorization: `Bearer ${auth?.access_token}`,
    },
  });
};