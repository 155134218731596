import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Button,
  Divider,
  Carousel,
  Form,
  Avatar,
  Modal,
  Spin,
  message,
  InputNumber,
  Select,
  Rate,
  DatePicker,
} from "antd";
import { EyeOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import "../../styles/properties.css";
import { authAxios } from "../../utils/axiosMiddleware";
import axios from "axios";
import { base_url, bucket_base_url } from "../../constants/constant";
import { FormattedMessage } from "react-intl";
import { status, propertyType, timeFrame, source } from "../../utils/constant";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function PropertyDetails() {
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("userData"));

  const { state } = useLocation();
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 64,
        color: "#E5BE54",
      }}
      spin
    />
  );

  const [form] = Form.useForm();
  const formRef = useRef(null);
  const param = useParams();
  const { id } = param;
  const [property, setProperty] = useState();
  const [spin, setSpin] = useState(false);
  const [agent, setAgent] = useState();

  const details = async () => {
    setSpin(true);
    try {
      const response = await axios.get(`${base_url}/property-listings/${id}`, {
        headers: {
          "ngrok-skip-browser-warning": true,
        },
      });
      setProperty(response?.data);
      setSpin(false);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const getAgent = async (id) => {
    try {
      const response = await axios.get(`${base_url}/users/${id}`, {
        headers: {
          "ngrok-skip-browser-warning": true,
        },
      });
      setAgent(response?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    if (!property) {
      details();
    }
    if (property?.userId) {
      getAgent(property?.userId);
    }
  }, [property]);

  const propertyPhotos = property?.file;
  const [disable, setDisable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onFinish = async (values) => {
    if (!auth) {
      message.error("Please log in/Resgister first to submit a contact request.");
      return; 
    }
    setDisable(true);
    try {
      await authAxios.post(`${base_url}/leads`, {
        firstName: values?.name.split(" ")[0],
        lastName: values?.name.split(" ")[1] || "",
        email: values?.email,
        phone: values?.phone,
        leadId: Number(values?.leadId),
        MinBudget: String(values?.budget?.min),
        MaxBudget: String(values?.budget?.max),
        financing: values?.financing,
        source: values?.source,
        passport: values?.passport,
        passportExpiry: values?.passportExpiry ? values?.passportExpiry.toISOString() : null,
        status: property?.status, 
        property_type: property?.property_type,
        Timeframe: values?.time_frame,
        shortlisted: values?.shortlisted === "yes" ? 1 : 0,
        quality: values?.quality,
        notes: values?.notes,
        assignedUserId: agent?.id,
        assignedUserName: agent?.name || "Unknown",
      });
      setIsModalVisible(true);
      formRef.current.resetFields();
      setDisable(false);
    } catch (err) {
      message?.error(err?.response?.data?.message);
      setDisable(false);
    }
  };

  return (
    <>
      {spin && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "110px",
          }}
        >
          <Spin spinning={spin} size="larger" indicator={antIcon} />
        </div>
      )}

      {property && (
        <>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className="first_col">
                <div className="back-screen" onClick={() => navigate(-1)}>
                  <img src="/icons/leftarrowb.png" alt="" />
                  <span style={{ marginLeft: "5px" }}>
                    <FormattedMessage id="back" />
                  </span>
                </div>
                <Carousel
                  dots
                  style={{ width: "770px", height: "500px" }}
                  autoplay={true}
                >
                  {property?.file?.map((image, index) => {
                    return (
                      <div
                        style={{
                          marginTop: "22px",
                          width: "770px",
                          height: "500px",
                        }}
                        key={index}
                      >
                        <img
                          style={{ width: "770px", height: "500px" }}
                          src={
                            image?.filePath?.includes("http")
                              ? image?.filePath
                              : `${bucket_base_url}${image?.filePath}`
                          }
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Carousel>
                <div
                  style={{
                    width: "770px",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "16px",
                  }}
                >
                  <img
                    className="lower-images"
                    src={
                      propertyPhotos[0]?.filePath?.includes("http")
                        ? propertyPhotos[0]?.filePath
                        : `${bucket_base_url}${propertyPhotos[0]?.filePath}`
                    }
                    alt=""
                  />
                  <img
                    className="lower-images"
                    src={
                      propertyPhotos[1]?.filePath?.includes("http")
                        ? propertyPhotos[1]?.filePath
                        : `${bucket_base_url}${propertyPhotos[1]?.filePath}`
                    }
                    alt=""
                  />
                  <img
                    className="lower-images"
                    src={
                      propertyPhotos[2]?.filePath?.includes("http")
                        ? propertyPhotos[2]?.filePath
                        : `${bucket_base_url}${propertyPhotos[2]?.filePath}`
                    }
                    alt=""
                  />
                  <img
                    className="lower-images"
                    src={
                      propertyPhotos[3]?.filePath?.includes("http")
                        ? propertyPhotos[3]?.filePath
                        : `${bucket_base_url}${propertyPhotos[3]?.filePath}`
                    }
                    alt=""
                  />
                </div>
                <div className="s-description">
                  <span className="font-style">{property?.title}</span>
                </div>
                <div
                  className="s-description"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>
                    <img src="/icons/bagicon.png" alt="" />
                    <span className="price-detail">
                      AED {`${property?.price}  ${property?.rental_period}`}
                    </span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <img src="/icons/location.svg" alt="" />
                    <span className="location-detail">
                      {property?.community},{property?.city}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "770px",
                    marginTop: "20px",
                  }}
                >
                  <img src="/icons/homeicon.png" alt="" />
                  <span className="details-type">
                    {property?.property_type} for {property?.offering_type}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "770px",
                    marginTop: "20px",
                  }}
                >
                  <EyeOutlined style={{ fontSize: "20px" }} />
                  <span className="details-type">
                    <FormattedMessage id="Views" /> {property?.viewsCount}
                  </span>
                </div>

                <div className="s-description">
                  <div className="house-details">
                    <div className="box-div">
                      <img src="/icons/bedrect.png" alt="" />
                      <span className="box-text">
                        {property?.bedroom} Rooms
                      </span>
                    </div>
                    <Divider
                      type="vertical"
                      style={{ width: "2px", color: "black" }}
                    />
                    <div className="box-div">
                      <img src="/icons/bathrect.png" alt="" />
                      <span className="box-text">
                        {property?.bathroom} baths
                      </span>
                    </div>
                    <Divider
                      type="vertical"
                      style={{ width: "2px", color: "black" }}
                    />

                    <div className="box-div">
                      <img src="/icons/space.png" alt="" />
                      <span className="box-text">{`${property?.size} SQFT`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <div className="contact-box">
                  <span className="heading-contact">
                    <FormattedMessage id="contact_us" />
                  </span>
                  <Form
                    initialValues={{
                      name: state?.profileName,
                      email: state?.email,
                      phone: state?.phone,
                      address: state?.address,
                      propertyType: state?.propertyType,
                      MinBudget: state?.MinBudget,
                      MaxBudget: state?.MaxBudget,
                      status: state?.status,
                      type: state?.propertyType,
                      time_frame: state?.timeFrame,
                      quality: state?.quality,
                      shortlisted: state?.shortlisted,
                      notes: state?.notes,
                      passport: state?.passport,
                      source: state?.source,
                      financing: state?.financing,
                      passportExpiry: state?.passportExpiry,
                    }}
                    onFinish={onFinish}
                    form={form}
                    ref={formRef}
                    style={{ marginTop: '10px' }}
                  >
                    <Row gutter={[16, 16]}> 
                      <Col span={12}>
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the name",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Name"
                            autoComplete="off"
                            data-testid="input-user-first-name"
                            className="input"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              type: "email",
                              message: "Please enter a valid email",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Email" autoComplete="off" className="input" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Phone Number",
                            },
                            {
                              min: 10,
                              message: "Phone number must be greater 10 digits.",
                            },
                          ]}
                        >
                          <PhoneInput
                            inputProps={{
                              name: "phone",
                              required: true,
                            }}
                            countryCodeEditable={false}
                            country={"us"}
                            prefix="+"
                            inputStyle={{
                              width: "100%",
                              height: "55px",
                              backgroundColor: "white",
                              marginTop: "8px",
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          required
                        >
                          <Input.Group compact>
                            <Form.Item
                              name={['budget', 'min']}
                              noStyle
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter the minimum budget',
                                },
                              ]}
                            >
                              <InputNumber 
                              placeholder="Min Budget" 
                              data-testid="input-user-budget"
                              className="input"
                              style={{ height: '48px', width: '50%' }} 
                              />
                            </Form.Item>

                            <Form.Item
                              name={['budget', 'max']}
                              noStyle
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter the maximum budget',
                                },
                              ]}
                            >
                              <InputNumber 
                              placeholder="Max Budget" 
                              data-testid="input-user-budget"
                              className="input"
                              style={{ height: '48px', width: '50%' }}
                              />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        name="financing"
                        rules={[
                          {
                            required: true,
                            message: "Please select the financing option",
                          },
                        ]}
                      >
                        <Select 
                        placeholder="Select Financing"
                        className="select"
                        style={{ height: '48px', width: '100%' }} 
                        >
                          <Select.Option value="Cash">Cash</Select.Option>
                          <Select.Option value="Mortgage">Mortgage</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="source"
                        rules={[
                          {
                            required: true,
                            message: "Please select the source",
                          },
                        ]}
                      >
                        <Select 
                        placeholder="Select Source"
                        className="select"
                        style={{ height: '48px', width: '100%' }} 
                        >
                          {source.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                              {option.key}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          name="time_frame"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the time frame",
                            },
                          ]}
                        >
                          <Select placeholder="Time Frame" className="select">
                            {timeFrame?.map((option) => (
                              <Select.Option key={option?.key} value={option?.value}>
                                {option?.key}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="shortlisted"
                        >
                          <Select
                            placeholder="Shortlisted"
                            className="select"
                            style={{
                              height: '48px',
                              lineHeight: '48px',
                              display: 'flex',
                              alignItems: 'center', 
                            }} 
                          >
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          name="quality"
                          label="Rate the Quality"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          style={{ marginLeft: '10px'}}
                        >
                          <div className="rating-container">
                            <Rate
                              count={5}
                              style={{ color: "#fadb14" }} 
                              onChange={(value) => 
                                form.setFieldsValue({ quality: value })
                              }
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        name="notes"
                      >
                        <Input.TextArea
                          placeholder="Enter Notes"
                          autoComplete="off"
                          className="input"
                        />
                      </Form.Item>
                    </Col>
                    </Row>
                    <Form.Item>
                      <Button
                        className="contact-button"
                        htmlType="submit"
                        disabled={disable}
                      >
                        <span className="button-text">Contact</span>
                      </Button>
                    </Form.Item>
                  </Form>

                </div>
                <div className="agent-box">
                  <span className="agent-text">agent</span>
                  <div className="agent-details">
                    {agent?.agent_photo ? (
                      <img
                        src={property?.agent_photo}
                        alt=""
                        style={{
                          marginLeft: "20px",
                          width: "84px",
                          height: "84px",
                          borderRadius: "10px",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <Avatar
                        style={{ marginLeft: "20px" }}
                        shape="square"
                        size={84}
                        icon={<UserOutlined />}
                      />
                    )}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex" }}>
                        <span className="agent-name">{agent?.name}</span>
                      </div>
                      {agent?.agent_phone && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "20px",
                            height: "35px",
                          }}
                        >
                          <img
                            style={{ width: "14px", height: "14px" }}
                            src="/icons/phone1.png"
                            alt=""
                          />
                          <p className="listing-agent-details">
                            {agent?.agent_phone}
                          </p>
                        </div>
                      )}
                      {agent?.email && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "20px",
                            height: "35px",
                          }}
                        >
                          <img
                            style={{ width: "14px", height: "14px" }}
                            src="/icons/email1.png"
                            alt=""
                          />
                          <p className="listing-agent-details">
                            {agent?.email}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              height: "auto",
              marginTop: "40px",
              marginLeft: "130px",
            }}
          >
            <Col span={16}>
              <span className="description-section">DESCRIPTTION</span>
              <div
                dangerouslySetInnerHTML={{
                  __html: property?.description_en,
                }}
              ></div>
            </Col>
          </Row>
        </>
      )}
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        bodyStyle={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        okButtonProps={{ style: { backgroundColor: "#E5BE54" } }}
      >
        <p style={{ fontSize: "20px", width: "100%" }}>
          <FormattedMessage id="we_have_received" />
        </p>
      </Modal>
    </>
  );
}
